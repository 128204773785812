/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiDispositivoMobileDeletePost } from '../fn/dispositivo-mobile/api-dispositivo-mobile-delete-post';
import { ApiDispositivoMobileDeletePost$Params } from '../fn/dispositivo-mobile/api-dispositivo-mobile-delete-post';
import { apiDispositivoMobileGet } from '../fn/dispositivo-mobile/api-dispositivo-mobile-get';
import { ApiDispositivoMobileGet$Params } from '../fn/dispositivo-mobile/api-dispositivo-mobile-get';
import { apiDispositivoMobileGetConfigPost } from '../fn/dispositivo-mobile/api-dispositivo-mobile-get-config-post';
import { ApiDispositivoMobileGetConfigPost$Params } from '../fn/dispositivo-mobile/api-dispositivo-mobile-get-config-post';
import { apiDispositivoMobileInsertOrUpdatePost } from '../fn/dispositivo-mobile/api-dispositivo-mobile-insert-or-update-post';
import { ApiDispositivoMobileInsertOrUpdatePost$Params } from '../fn/dispositivo-mobile/api-dispositivo-mobile-insert-or-update-post';
import { apiDispositivoMobileSaveConfigPost } from '../fn/dispositivo-mobile/api-dispositivo-mobile-save-config-post';
import { ApiDispositivoMobileSaveConfigPost$Params } from '../fn/dispositivo-mobile/api-dispositivo-mobile-save-config-post';
import { apiDispositivoMobileSetConfermaPushNotificationPost } from '../fn/dispositivo-mobile/api-dispositivo-mobile-set-conferma-push-notification-post';
import { ApiDispositivoMobileSetConfermaPushNotificationPost$Params } from '../fn/dispositivo-mobile/api-dispositivo-mobile-set-conferma-push-notification-post';
import { apiDispositivoMobileUpdateDeviceUidPost } from '../fn/dispositivo-mobile/api-dispositivo-mobile-update-device-uid-post';
import { ApiDispositivoMobileUpdateDeviceUidPost$Params } from '../fn/dispositivo-mobile/api-dispositivo-mobile-update-device-uid-post';
import { DispositivoMobileConfigDto } from '../models/dispositivo-mobile-config-dto';

@Injectable({ providedIn: 'root' })
export class DispositivoMobileService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiDispositivoMobileInsertOrUpdatePost()` */
    static readonly ApiDispositivoMobileInsertOrUpdatePostPath = '/api/dispositivo-mobile/insert-or-update';

    /**
     * Inserisce o aggiorna l'associazione tra un dispositivo mobile e un utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiDispositivoMobileInsertOrUpdatePost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileInsertOrUpdatePost$Response(
        params: ApiDispositivoMobileInsertOrUpdatePost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<boolean>> {
        return apiDispositivoMobileInsertOrUpdatePost(this.http, this.rootUrl, params, context);
    }

    /**
     * Inserisce o aggiorna l'associazione tra un dispositivo mobile e un utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiDispositivoMobileInsertOrUpdatePost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileInsertOrUpdatePost(
        params: ApiDispositivoMobileInsertOrUpdatePost$Params,
        context?: HttpContext
    ): Observable<boolean> {
        return this.apiDispositivoMobileInsertOrUpdatePost$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body)
        );
    }

    /** Path part for operation `apiDispositivoMobileUpdateDeviceUidPost()` */
    static readonly ApiDispositivoMobileUpdateDeviceUidPostPath = '/api/dispositivo-mobile/update-device-uid';

    /**
     * Aggiorna il device uid salvato nel database in base al cambiamento avvenuto nell'app mobile.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiDispositivoMobileUpdateDeviceUidPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileUpdateDeviceUidPost$Response(
        params: ApiDispositivoMobileUpdateDeviceUidPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<boolean>> {
        return apiDispositivoMobileUpdateDeviceUidPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Aggiorna il device uid salvato nel database in base al cambiamento avvenuto nell'app mobile.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiDispositivoMobileUpdateDeviceUidPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileUpdateDeviceUidPost(
        params: ApiDispositivoMobileUpdateDeviceUidPost$Params,
        context?: HttpContext
    ): Observable<boolean> {
        return this.apiDispositivoMobileUpdateDeviceUidPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body)
        );
    }

    /** Path part for operation `apiDispositivoMobileDeletePost()` */
    static readonly ApiDispositivoMobileDeletePostPath = '/api/dispositivo-mobile/delete';

    /**
     * Elimina un dispositivo mobile dalla tabella.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiDispositivoMobileDeletePost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileDeletePost$Response(
        params: ApiDispositivoMobileDeletePost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<boolean>> {
        return apiDispositivoMobileDeletePost(this.http, this.rootUrl, params, context);
    }

    /**
     * Elimina un dispositivo mobile dalla tabella.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiDispositivoMobileDeletePost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileDeletePost(params: ApiDispositivoMobileDeletePost$Params, context?: HttpContext): Observable<boolean> {
        return this.apiDispositivoMobileDeletePost$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
    }

    /** Path part for operation `apiDispositivoMobileSaveConfigPost()` */
    static readonly ApiDispositivoMobileSaveConfigPostPath = '/api/dispositivo-mobile/save-config';

    /**
     * Salva i settaggi di un dispositivo mobile.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiDispositivoMobileSaveConfigPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileSaveConfigPost$Response(
        params: ApiDispositivoMobileSaveConfigPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<boolean>> {
        return apiDispositivoMobileSaveConfigPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Salva i settaggi di un dispositivo mobile.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiDispositivoMobileSaveConfigPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileSaveConfigPost(params: ApiDispositivoMobileSaveConfigPost$Params, context?: HttpContext): Observable<boolean> {
        return this.apiDispositivoMobileSaveConfigPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body)
        );
    }

    /** Path part for operation `apiDispositivoMobileGetConfigPost()` */
    static readonly ApiDispositivoMobileGetConfigPostPath = '/api/dispositivo-mobile/get-config';

    /**
     * Carica le configurazioni del dispositivo mobile.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiDispositivoMobileGetConfigPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileGetConfigPost$Response(
        params: ApiDispositivoMobileGetConfigPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<DispositivoMobileConfigDto>> {
        return apiDispositivoMobileGetConfigPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica le configurazioni del dispositivo mobile.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiDispositivoMobileGetConfigPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileGetConfigPost(
        params: ApiDispositivoMobileGetConfigPost$Params,
        context?: HttpContext
    ): Observable<DispositivoMobileConfigDto> {
        return this.apiDispositivoMobileGetConfigPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<DispositivoMobileConfigDto>): DispositivoMobileConfigDto => r.body)
        );
    }

    /** Path part for operation `apiDispositivoMobileSetConfermaPushNotificationPost()` */
    static readonly ApiDispositivoMobileSetConfermaPushNotificationPostPath = '/api/dispositivo-mobile/set-conferma-push-notification';

    /**
     * Inserisce la conferma di ricevuta di una push notification.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiDispositivoMobileSetConfermaPushNotificationPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileSetConfermaPushNotificationPost$Response(
        params: ApiDispositivoMobileSetConfermaPushNotificationPost$Params,
        context?: HttpContext
    ): Observable<StrictHttpResponse<void>> {
        return apiDispositivoMobileSetConfermaPushNotificationPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Inserisce la conferma di ricevuta di una push notification.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiDispositivoMobileSetConfermaPushNotificationPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiDispositivoMobileSetConfermaPushNotificationPost(
        params: ApiDispositivoMobileSetConfermaPushNotificationPost$Params,
        context?: HttpContext
    ): Observable<void> {
        return this.apiDispositivoMobileSetConfermaPushNotificationPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<void>): void => r.body)
        );
    }

    /** Path part for operation `apiDispositivoMobileGet()` */
    static readonly ApiDispositivoMobileGetPath = '/api/dispositivo-mobile/';

    /**
     * Carica le configurazione di tutti i device.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiDispositivoMobileGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiDispositivoMobileGet$Response(params: ApiDispositivoMobileGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
        return apiDispositivoMobileGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica le configurazione di tutti i device.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiDispositivoMobileGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiDispositivoMobileGet(params: ApiDispositivoMobileGet$Params, context?: HttpContext): Observable<void> {
        return this.apiDispositivoMobileGet$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }
}
